<script>
	export let areaSlug
	export let dataScope = null
	export let geojson = null
	export let indicator = null
	export let selectedYear
	export let year1_OldestData
	export let year2_OldestData
	export let year1_NewestData
	export let year2_NewestData
	export let years
	export let compareMode
	export let compareButton
	export let areaSlugs
	export let heightWriter = false

	import { createEventDispatcher, setContext } from "svelte"
	import { createPopperActions } from "svelte-popperjs"
	import { writable } from "svelte/store"
	import { beforeUrlChange, goto } from "@sveltech/routify"
	import { download } from "~/heroicons.js"
	import { DATA_SCOPE } from "~/indicator.js"
	import { indicatorsMetadata, territoriesMetadata } from "~/stores.js"
	import { slugsToUrlParam } from "~/url.js"
	import AreaPicker from "~/components/AreaPicker.svelte"
	import IndicatorSource from "~/components/IndicatorSource.svelte"
	import IndicatorTooltip from "~/components/IndicatorTooltip.svelte"
	import SvgIcon from "~/components/SvgIcon.svelte"
	import YearSlider from "~/components/YearSlider.svelte"
	import TwoYearsSlider from "~/components/TwoYearsSlider.svelte"
	import {
		downloadMapPng,
		downloadMapCsv,
	} from "~/components/charts/download.js"
	import DataScopePicker from "./DataScopePicker.svelte"
	import MapChart from "./MapChart.svelte"
	import ScaleLegend from "./ScaleLegend.svelte"
	import { copyright } from "./legend.js"

	const dispatch = createEventDispatcher()

	const featureTooltip = writable(null)

	setContext("Map", { areaSlug, featureTooltip, indicator, selectedYear })

	$beforeUrlChange(() => {
		// Remove tooltip before changing URL because it uses a portal.
		$featureTooltip = null
		return true
	})

	$: indicatorMetadata = indicator
		? $indicatorsMetadata.find(({ slug }) => slug === indicator.slug)
		: null

	let downloadContext
	$: if (dataScope) {
		downloadContext = {}
		downloadContext.dataScope = dataScope
		if (dataScope === DATA_SCOPE.YEARS) {
			downloadContext.year = selectedYear
		} else if (dataScope === DATA_SCOPE.OLDEST_YEARS) {
			downloadContext.year1 = year1_OldestData
			downloadContext.year2 = year2_OldestData
		} else if (dataScope === DATA_SCOPE.NEWEST_YEARS) {
			downloadContext.year1 = year1_NewestData
			downloadContext.year2 = year2_NewestData
		}
	}

	const [menuPopperRef, menuPopperContent] = createPopperActions()
	const menuPopperOptions = {
		placement: "bottom-end",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, 8],
				},
			},
		],
	}

	let showDownloadMenu = false

	function interceptClick(event) {
		showDownloadMenu = false
		window.removeEventListener("click", interceptClick, true)
		if (event.target.classList.contains("menu-item")) {
			event.target.click()
		}
	}

	function toggleDownloadMenu() {
		showDownloadMenu = !showDownloadMenu
		if (showDownloadMenu) {
			window.addEventListener("click", interceptClick, true)
		} else {
			window.removeEventListener("click", interceptClick, false)
		}
	}
</script>

<div class="flex flex-col h-full mb-3">
	<div class="flex flex-no-wrap items-start justify-between indicator-title">
		<div class="mr-4">
			<div class="text-2xl">
				{indicator.long_name}
				<IndicatorTooltip
					indicatorDescription={indicatorMetadata.description} />
			</div>
			<div class="text-lg text-gray-800">
				{#if dataScope == DATA_SCOPE.YEARS}
					{selectedYear}
				{:else if dataScope === DATA_SCOPE.OLDEST_YEARS}
					Valeurs les plus anciennes entre
					{year1_OldestData}
					et
					{year2_OldestData}
				{:else if dataScope === DATA_SCOPE.NEWEST_YEARS}
					Valeurs les plus récentes entre
					{year1_NewestData}
					et
					{year2_NewestData}
				{/if}
			</div>
		</div>
		<div class="flex flex-wrap do-not-print">
			<span
				class="cursor-pointer text-gray-500 hover:text-black"
				use:menuPopperRef
				title="Télécharger la carte ou les données"
				on:click={() => toggleDownloadMenu()}>
				<SvgIcon size={20} {...download} />
			</span>
			{#if showDownloadMenu}
				<div
					id="tooltip"
					class="z-10 bg-white rounded shadow-lg p-2 border"
					use:menuPopperContent={menuPopperOptions}>
					<ul>
						<li>
							<button
								class="menu-item"
								on:click={(event) => downloadMapPng(event, indicator, downloadContext)}>Télécharger
								la carte au format PNG</button>
						</li>
						<li>
							<button
								class="menu-item"
								on:click={() => downloadMapCsv(indicator, $territoriesMetadata, downloadContext)}>Télécharger
								les données affichées au format Excel (xlsx)
							</button>
						</li>
						<li>
							<button
								class="menu-item"
								on:click={() => downloadMapCsv(indicator, $territoriesMetadata)}>
								Télécharger toutes les données au format Excel (xlsx)
							</button>
						</li>
					</ul>
				</div>
			{/if}
		</div>
	</div>

	{#if geojson}
		<MapChart {areaSlug} data={geojson} {heightWriter} />
	{/if}

	<div class="text-gray-600 text-right source-and-copyright">
		<IndicatorSource source={indicator.source} />
		<br />
		{copyright}
	</div>

	<div class="my-2">
		<ScaleLegend
			scale={indicator.scale}
			legend={indicator.legend}
			naColor={indicator.na_color} />
	</div>

	{#if years.length > 0}
		<div
			class="do-not-print year-slider text-sm bg-gray-200"
			style="padding: 5px;border-radius: 7px;">
			{#if dataScope === DATA_SCOPE.YEARS}
				<YearSlider bind:value={selectedYear} {years} />
			{:else if dataScope === DATA_SCOPE.OLDEST_YEARS}
				<TwoYearsSlider
					bind:value1={year1_OldestData}
					bind:value2={year2_OldestData}
					{years} />
			{:else if dataScope === DATA_SCOPE.NEWEST_YEARS}
				<TwoYearsSlider
					bind:value1={year1_NewestData}
					bind:value2={year2_NewestData}
					{years} />
			{/if}
		</div>
	{/if}

	<div class="mt-3 do-not-print data-scope">
		<div class="flex items-center gap-4 flex-wrap">
			<div class="flex-1" style="max-width: 40%">
				<DataScopePicker bind:value={dataScope} />
			</div>
			<div class="flex-1" style="max-width: 40%">
				<AreaPicker
					value={areaSlug}
					onlyValues={indicatorMetadata ? indicatorMetadata.area_slugs : null}
					on:select={({ detail }) => {
						dispatch('area-select', detail.slug)
					}} />
			</div>
			{#if compareButton}
				<label class="whitespace-no-wrap ml-auto">
					<input
						type="checkbox"
						checked={compareMode}
						on:change={(event) => {
							$goto('./', {
								areaSlug: slugsToUrlParam(
									event.target.checked
										? [areaSlugs[0], areaSlugs[0]]
										: [areaSlugs[0]],
									{ allowDuplicates: true },
								),
							})
						}} />
					Comparer
				</label>
			{/if}
		</div>
	</div>
</div>

<style lang="postcss">
	.menu-item {
		@apply text-gray-800 px-2 py-1 w-full text-left;
	}

	.menu-item:hover {
		@apply text-black bg-gray-200 rounded;
	}
</style>
