<script>
	export let d
	export let viewBox
	export let size
	export { className as class }
	export let variant

	$: if (variant !== "outline" && variant !== "solid") {
		throw new Error(`Variant ${variant} is not supported`)
	}

	let stroke
	let fill
	let pathClipRule = null
	let pathFillRule = null
	let strokeLinecap = null
	let strokeLinejoin = null
	let strokeWidth = null

	$: if (variant === "outline") {
		fill = "none"
		stroke = "currentColor"
		strokeLinecap = strokeLinejoin = "round"
		strokeWidth = 2
	} else {
		// variant "solid"
		fill = "currentColor"
		pathClipRule = pathFillRule = "evenodd"
	}
	$: fill = variant === "outline" ? "none" : "currentColor"

	let className = null
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	class={className}
	{viewBox}
	width={size}
	height={size}
	{stroke}
	stroke-linecap={strokeLinecap}
	stroke-linejoin={strokeLinejoin}
	stroke-width={strokeWidth}
	{fill}>
	<path clip-rule={pathClipRule} fill-rule={pathFillRule} {d} />
</svg>

<style>
	svg {
		display: inline-block;
	}
</style>
