<script>
	export let areaSlugs
	export let indicators = []
	export let topologies = []

	import { setContext } from "svelte"
	import { writable } from "svelte/store"
	import { goto } from "@sveltech/routify"
	import {
		buildMapGeoJson,
		buildMapGeoJsonOldestYears,
		buildMapGeoJsonNewestYears,
		DATA_SCOPE,
		getYearsWithAnyValue,
	} from "~/indicator.js"
	import { slugsToUrlParam } from "~/url.js"
	import { getBreakpoint } from "~/theme.js"
	import Map from "~/components/charts/map/Map.svelte"

	const mapHeight = writable()
	setContext("ComparableMap", { mapHeight })

	const currentYear = new Date().getFullYear()

	let years = []

	$: if (areaSlugs.length > 0 && indicators.length > 0) {
		years = areaSlugs.map((_, index) =>
			getYearsWithAnyValue(indicators[index].observations),
		)
	}

	function getCurrentOrMaxYear(index) {
		const yearsWithAnyValue = years[index]
		const maxYear = yearsWithAnyValue[yearsWithAnyValue.length - 1]
		return Math.min(currentYear, maxYear)
	}

	let selectedYears = []

	$: if (selectedYears.length === 0) {
		if (areaSlugs.length === 1) {
			selectedYears[0] = getCurrentOrMaxYear(0)
		} else if (areaSlugs.length === 2) {
			selectedYears[0] = years[0][0]
			selectedYears[1] = getCurrentOrMaxYear(1)
		}
	}

	$: compareMode =
		areaSlugs.length === 2 && geojsons.length === 2 && indicators.length === 2

	let dataScopes = []
	$: if (dataScopes.length === 0) {
		if (areaSlugs.length === 2) {
			dataScopes = [DATA_SCOPE.YEARS, DATA_SCOPE.YEARS]
		} else {
			dataScopes = [DATA_SCOPE.YEARS]
		}
	}

	function findYear2OldestData(years, year1) {
		let year2 = year1 + 10
		while (years.indexOf(year2) === -1) {
			year2--
		}
		return year2
	}
	let years_OldestData = []
	$: if (years_OldestData.length === 0) {
		years_OldestData = years.map((_, index) => {
			const yearsList = years[index]
			const year1 = yearsList[0]
			const year2 = findYear2OldestData(yearsList, year1)
			return { year1: year1, year2: year2 }
		})
	}

	function findYear1NewestData(years, year2) {
		let year1 = year2 - 10
		while (years.indexOf(year1) === -1) {
			year1++
		}
		return year1
	}

	function findYear2NewestData(years) {
		const currentYear = new Date().getFullYear()
		for (let index = years.length - 1; index >= 0; index--) {
			if (years[index] <= currentYear) {
				return years[index]
			}
		}
	}

	let years_NewestData = []
	$: if (years_NewestData.length === 0) {
		years_NewestData = years.map((_, index) => {
			const yearsList = years[index]
			const year2 = findYear2NewestData(yearsList)
			const year1 = findYear1NewestData(yearsList, year2)
			return { year1: year1, year2: year2 }
		})
	}

	let geojsons = []
	$: if (
		areaSlugs.length > 0 &&
		indicators.length > 0 &&
		topologies.length > 0 &&
		selectedYears.length > 0 &&
		years_OldestData.length > 0 &&
		years_NewestData.length > 0
	) {
		geojsons = areaSlugs.map((_, index) => {
			const indicator = indicators[index]
			const topology = topologies[index]
			if (dataScopes[index] === DATA_SCOPE.YEARS) {
				const year = selectedYears[index]
				if (topology && indicator && year) {
					return buildMapGeoJson(topology, indicator, year)
				}
			} else if (dataScopes[index] === DATA_SCOPE.OLDEST_YEARS) {
				const year1 = years_OldestData[index].year1
				const year2 = years_OldestData[index].year2
				if (topology && indicator && year1 && year2) {
					return buildMapGeoJsonOldestYears(topology, indicator, year1, year2)
				}
			} else if (dataScopes[index] === DATA_SCOPE.NEWEST_YEARS) {
				const year1 = years_NewestData[index].year1
				const year2 = years_NewestData[index].year2
				if (topology && indicator && year1 && year2) {
					return buildMapGeoJsonNewestYears(topology, indicator, year1, year2)
				}
			}
		})
	}

	// Responsiveness based on component width, not screen width.
	let clientWidth = null
	const smBreakpoint = getBreakpoint("sm")
	$: isTwoColumns = clientWidth !== null && clientWidth >= smBreakpoint
</script>

<div bind:clientWidth>
	{#if clientWidth !== null}
		<div class="grid gap-4 text-xs" class:grid-cols-2={isTwoColumns}>
			<!-- This div wrapper allows cards to have different height (e.g. when viewing Africa + World). -->
			<div>
				<div class="card">
					<Map
						areaSlug={areaSlugs[0]}
						bind:dataScope={dataScopes[0]}
						geojson={geojsons[0]}
						indicator={indicators[0]}
						bind:selectedYear={selectedYears[0]}
						bind:year1_OldestData={years_OldestData[0].year1}
						bind:year2_OldestData={years_OldestData[0].year2}
						bind:year1_NewestData={years_NewestData[0].year1}
						bind:year2_NewestData={years_NewestData[0].year2}
						years={years[0]}
						heightWriter
						bind:compareMode
						compareButton={true}
						{areaSlugs}
						on:area-select={({ detail }) => {
							$goto('./', {
								areaSlug: slugsToUrlParam(
									compareMode ? [detail, areaSlugs[1]] : [detail],
									{ allowDuplicates: true },
								),
							})
						}} />
				</div>
			</div>
			{#if compareMode}
				<div class="card">
					<Map
						areaSlug={areaSlugs[1]}
						bind:dataScope={dataScopes[1]}
						geojson={geojsons[1]}
						indicator={indicators[1]}
						bind:selectedYear={selectedYears[1]}
						bind:year1_OldestData={years_OldestData[1].year1}
						bind:year2_OldestData={years_OldestData[1].year2}
						bind:year1_NewestData={years_NewestData[1].year1}
						bind:year2_NewestData={years_NewestData[1].year2}
						years={years[1]}
						{areaSlugs}
						on:area-select={({ detail }) => {
							$goto('./', {
								areaSlug: slugsToUrlParam([areaSlugs[0], detail], {
									allowDuplicates: true,
								}),
							})
						}} />
				</div>
			{/if}
		</div>
	{/if}
</div>
