<script>
	export let indicatorDescription

	import { createPopperActions } from "svelte-popperjs"
	import { information } from "~/heroicons.js"
	import SvgIcon from "~/components/SvgIcon.svelte"

	const [menuPopperRef, menuPopperContent] = createPopperActions()
	const menuPopperOptions = {
		placement: "bottom",
		modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
	}

	let showIndicatorInfo = false

	let descriptionElement

	function handleWindowClick(event) {
		if (descriptionElement.contains(event.target)) {
			return
		}
		showIndicatorInfo = false
		window.removeEventListener("click", handleWindowClick, true)
		event.stopPropagation()
	}

	function toggleShowIndicatorInfo() {
		showIndicatorInfo = !showIndicatorInfo
		if (showIndicatorInfo) {
			window.addEventListener("click", handleWindowClick, true)
		} else {
			window.removeEventListener("click", handleWindowClick, false)
		}
	}
</script>

{#if indicatorDescription}
	<span
		class="do-not-print cursor-pointer text-gray-500 hover:text-black"
		use:menuPopperRef
		on:click={toggleShowIndicatorInfo}>
		<SvgIcon size={20} {...information} />
	</span>
	{#if showIndicatorInfo}
		<div
			class="z-10 bg-white rounded shadow-lg p-2 border text-base popper-indicator-description"
			use:menuPopperContent={menuPopperOptions}
			bind:this={descriptionElement}>
			{indicatorDescription}
		</div>
	{/if}
{/if}

<style>
	.popper-indicator-description {
		width: 20rem;
	}
</style>
