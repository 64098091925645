<script>
	// Select all indicators of the category of the first indicator.

	import { redirect } from "@sveltech/routify"
	import { AFRICA_SLUG } from "~/config.js"
	import { slugsToUrlParam } from "~/url.js"

	$redirect("maps", {
		areaSlug: slugsToUrlParam([AFRICA_SLUG, AFRICA_SLUG], {
			allowDuplicates: true,
		}),
	})
</script>

<!-- routify:options name="mapsIndicatorIndex" -->
