import { writable } from "svelte/store"

export const indicatorCategories = writable([])
export const indicatorsMetadata = writable([])
export const territoriesMetadata = writable({})

export const isDesktop = writable(false)

export const showSideBar = writable(false)

function createLoading() {
	const { subscribe, update } = writable(0)
	return {
		subscribe,
		wrap: (promise) => {
			update((n) => n + 1)
			return promise.finally((data) => {
				update((n) => n - 1)
				return data
			})
		},
	}
}

export const loading = createLoading()
