<script>
	import { url } from "@sveltech/routify"
	import config from "~/config.js"
	import SideBarLayout from "~/components/SideBarLayout.svelte"
</script>

<!-- routify:options name="about" -->

<svelte:head>
	<title>À propos / {config.appTitle}</title>
</svelte:head>

<SideBarLayout>
	<div class="prose max-w-6xl mx-auto">
		<h1>À propos</h1>

		<a href="https://www.afd.fr" target="_blank">
			<img class="h-24" src={$url('/logo-afd.png')} alt="Logo AFD" />
		</a>

		<h2>Cartes</h2>

		<p>
			Toutes les cartes de ce site reprennent
			<a
				href="https://fr.wikipedia.org/wiki/Projection_Eckert_IV"
				target="_blank">la projection Eckert IV</a>
			utilisée notamment dans
			<a
				href="https://www.afd.fr/fr/actualites/atlas-de-lafrique-afd-pour-un-autre-regard-sur-le-continent"
				target="_blank">«&nbsp;l’Atlas de l’Afrique AFD&nbsp;»</a>
			publié en août 2020 chez Armand Colin. Cette projection est reconnue pour
			refléter plus fidèlement la géographie du continent africain.
		</p>

		<h2>Données</h2>

		<p>
			La source des données est précisée pour chaque indicateur en dessous des
			cartes et graphiques.
		</p>

		<p>
			«&nbsp;L'Afrique en cartes&nbsp;» repose notamment sur la plateforme
			<a href="https://db.nomics.world/" target="_blank">DBnomics</a>
			pour visualiser les données de nombreux fournisseurs avec des mises à jour
			automatiques et régulières.
		</p>

		<a href="https://db.nomics.world/" target="_blank">
			<img class="h-20" src={$url('/logo-dbnomics.png')} alt="Logo DBnomics" />
		</a>

		<p>
			Certaines données n'étant pas disponibles dans DBnomics, elles ont fait
			l'objet d'un traitement spécifique et ne sont donc pas mises à jour
			automatiquement. En voici les sources :
		</p>

		<ul>
			<li>
				<span class="italic">Indice de développement humain</span>
				:
				<a href="http://hdr.undp.org/en/data" target="_blank">
					Programme des Nations unies pour le développement
				</a>
				(PNUD)
			</li>
			<li>
				<span class="italic">Écoles primaires - Eau potable, Électricité et
					Sanitaires</span>
				:
				<a href="http://data.uis.unesco.org/" target="_blank">
					Institut de statistique de l'UNESCO
				</a>
				(ISU)
			</li>
			<li>
				<span class="italic">Vulnérabilité physique au changement climatique</span>
				:
				<a
					href="https://ferdi.fr/donnees/indicateur-de-vulnerabilite-physique-au-changement-climatique"
					target="_blank">
					Fondation pour les études et recherches sur le développement
					international
				</a>
				(FERDI)
			</li>
			<li>
				<span class="italic">Indice ND-GAIN de vulnérabilité climatique</span>
				:
				<a href="https://gain.nd.edu/our-work/country-index/" target="_blank">
					Notre Dame Global Adaptation Initiative
				</a>
				(ND-GAIN)
			</li>
			<li>
				<span class="italic">Fragilité politique</span>
				:
				<a
					href="https://datacatalog.worldbank.org/dataset/harmonized-list-fragility-conflict-and-violence-countries"
					target="_blank">
					Banque mondiale
				</a>
			</li>
			<li>
				<span class="italic">Liberté dans le monde</span>
				:
				<a href="https://freedomhouse.org/report/freedom-world" target="_blank">
					Freedom House
				</a>
			</li>
			<li>
				<span class="italic">Régimes politiques</span>
				:
				<a
					href="http://www.systemicpeace.org/polity/polity4.htm"
					target="_blank">
					Polity IV Project
				</a>
			</li>
			<li>
				<span class="italic">Incubateurs de start-up</span>
				:
				<a href="https://theatlas.com/charts/r1iAH50FG" target="_blank">
					theatlas.com
				</a>
			</li>
		</ul>

		<h2>Réalisation</h2>

		<p>
			Ce site a été réalisé par
			<a href="https://jailbreak.paris/" target="_blank">Jailbreak</a>
			pour l'<a href="https://www.afd.fr" target="_blank">Agence française de
				développement</a>.
		</p>

		<p>
			Son code source est distribué sous
			<a
				href="https://www.gnu.org/licenses/agpl-3.0.fr.html"
				target="_blank">licence libre AGPLv3</a>
			à l'adresse suivante :
			<a
				href="https://gitlab.com/africartes/"
				target="_blank">https://gitlab.com/africartes/</a>.
		</p>

		<p>
			Vous pouvez à tout moment signaler un problème à l'adresse suivante :
			<a
				href="https://gitlab.com/africartes/africartes-frontend/-/issues/new"
				target="_blank">https://gitlab.com/africartes/africartes-frontend/-/issues/new</a>.
		</p>

		<h2>Vie privée</h2>

		<p>
			Ce site est respectueux de votre vie privée et ne collecte aucune donnée à
			caractère personnel.
		</p>

		<h2>Mentions légales</h2>

		<h3>Édition</h3>

		<p>
			<span class="font-bold">Agence française de développement</span><br />
			775 665 599<br />
			5 rue Roland Barthes<br />
			75 598 PARIS Cedex 12<br />
			+33 (0)1 53 44 31 31
		</p>

		<h3>Conception, création graphique, développement et suivi technique</h3>

		<p>
			<span class="font-bold">Jailbreak SAS</span><br />
			<a
				href="https://www.jailbreak.paris/"
				target="_blank">Jailbreak.paris</a><br />
			830 141 321<br />
			4 rue Rabelais<br />
			92170 Vanves
		</p>

		<h3>Hébergement</h3>

		<p>
			<span class="font-bold">Scaleway SAS</span><br />
			<a href="https://www.scaleway.com/" target="_blank">Scaleway.com</a><br />
			8 rue de la Ville l’Evêque<br />
			75008 Paris
		</p>

		<p>
			<span class="font-bold">Netlify Inc.</span><br />
			<a href="https://www.netlify.com/" target="_blank">Netlify.com</a><br />
			610 22nd Street, Suite 315<br />
			San Francisco, CA 94107
		</p>
		<br />
		<br />
	</div>
</SideBarLayout>
