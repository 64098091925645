<!-- routify:options name="home" -->
<script>
	import { redirect } from "@sveltech/routify"
	import config from "~/config.js"
	import SideBarLayout from "~/components/SideBarLayout.svelte"
</script>

<svelte:head>
	<title>{config.appTitle}</title>
</svelte:head>

<SideBarLayout padding={false}>
	<div
		class="absolute inset-0 below-navbar bg-cover bg-no-repeat bg-center cursor-pointer"
		style="background-image: url('/home.jpg')"
		title="Découvrir les indicateurs"
		on:click={() => $redirect('mapsIndex')}>
		<div
			class="absolute bottom-0 left-0 right-0 text-center p-4 font-bold tracking-wider text-base md:text-2xl"
			style="background-color: #ffffffaa; color: #22185fff;">
			<p>
				L’Afrique vue au travers d’une large sélection d’indicateurs couvrant
				plus d’une&nbsp;dizaine&nbsp;de&nbsp;thématiques
				<br />
				(économie, finances publiques, emploi, population, éducation,
				santé,&nbsp;environnement,&nbsp;etc.)
			</p>
		</div>
	</div>
</SideBarLayout>

<style>
	.below-navbar {
		/* Compensate NavBar height */
		top: 4rem;
	}
</style>
