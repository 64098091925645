import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import sanitizeFilename from "sanitize-filename"
import { DATA_SCOPE, NA } from "~/indicator.js"
import { arrayUnique } from "~/utils"
import xlsx from "xlsx"
import config from "~/config"

export function computeDownloadFilename(
	indicator,
	downloadContext,
	fileExtension,
) {
	// set filename with indicator name and downloadContext
	let filename = `${indicator.long_name || indicator.name || indicator.slug} - `
	if (!downloadContext) {
		filename += "toutes les années"
	} else if (downloadContext.dataScope === DATA_SCOPE.YEARS) {
		filename += downloadContext.year
	} else if (downloadContext.dataScope === DATA_SCOPE.OLDEST_YEARS) {
		filename += `plus anciennes entre ${downloadContext.year1} et ${downloadContext.year2}`
	} else if (downloadContext.dataScope === DATA_SCOPE.NEWEST_YEARS) {
		filename += `plus récentes entre ${downloadContext.year1} et ${downloadContext.year2}`
	}
	return filename + fileExtension
}

export function downloadMapPng(event, indicator, downloadContext) {
	const cardContent = event.target.closest(".card").firstChild

	const filename = computeDownloadFilename(indicator, downloadContext, ".png")

	html2canvas(cardContent, {
		height:
			cardContent.clientHeight -
			getAbsoluteHeight(".year-slider") -
			getAbsoluteHeight(".data-scope"),
		ignoreElements: function (el) {
			return el.classList.contains("do-not-print")
		},
		onclone: function (doc) {
			// center title
			doc.getElementsByClassName("indicator-title")[0].style.textAlign =
				"center"
			doc.getElementsByClassName("indicator-title")[0].style.display = "block"

			// adjust margin right of .source-and-copyright
			doc.getElementsByClassName("source-and-copyright")[0].style.marginRight =
				"16px"
		},
	}).then((canvas) => {
		saveAs(canvas.toDataURL(), sanitizeFilename(filename))
	})
}

/**
 * Filter observations along downloadContext.
 * @param {*} observations original observations
 * @param {*} downloadContext static year, newest or oldest values
 * Return an object whose keys are area codes and values are object { year1: value1, year2: value2 }
 */
function filterObservations(observations, downloadContext) {
	const filteredObs = {}

	if (!downloadContext) {
		// We want all the data
		observations.forEach((yearObj) => {
			const year = yearObj.Year
			Object.entries(yearObj).forEach((entry) => {
				let [k, v] = entry
				if (k != "Year") {
					if (!(k in filteredObs)) {
						filteredObs[k] = {}
					}
					filteredObs[k][year] = v
				}
			})
		})
		return filteredObs
	}

	if (downloadContext.dataScope === DATA_SCOPE.YEARS) {
		const year = downloadContext.year
		const yearObs = observations.find((elt) => elt.Year === year)
		if (yearObs !== undefined) {
			for (const [k, v] of Object.entries(yearObs)) {
				if (k != "Year") {
					// Retain the selected year value
					filteredObs[k] = { [year]: v }
				}
			}
		}
		return filteredObs
	}

	if (downloadContext.dataScope === DATA_SCOPE.NEWEST_YEARS) {
		const year1 = downloadContext.year1
		const year2 = downloadContext.year2

		observations
			.filter((yearObs) => yearObs.Year >= year1 && yearObs.Year <= year2)
			.forEach((yearObs) => {
				for (const [k, v] of Object.entries(yearObs)) {
					if (k != "Year") {
						// Retain the newest value
						filteredObs[k] = { [yearObs.Year]: v }
					}
				}
			})
		return filteredObs
	}

	if (downloadContext.dataScope === DATA_SCOPE.OLDEST_YEARS) {
		const year1 = downloadContext.year1
		const year2 = downloadContext.year2

		observations
			.filter((yearObs) => yearObs.Year >= year1 && yearObs.Year <= year2)
			.forEach((yearObs) => {
				for (const [k, v] of Object.entries(yearObs)) {
					// Take only 1st encountered value
					if (k != "Year" && !filteredObs.hasOwnProperty(k)) {
						filteredObs[k] = { [yearObs.Year]: v }
					}
				}
			})
		return filteredObs
	}
}

function saveXlsxAs(data, filename) {

	// trim '.xlsx' file extension
	const longTitle = filename.slice(0, -5)

	const wb = xlsx.utils.book_new()
	wb.Props = {
		Title: longTitle,
		Author: config.appTitle,
		CreatedDate: new Date()
	}

	const sheetName = "Données"
	wb.SheetNames.push(sheetName)
	wb.Sheets[sheetName] = xlsx.utils.aoa_to_sheet(data)
	const wbout = xlsx.write(wb, {bookType:'xlsx',  type: 'binary'});

	saveAs(new Blob([s2ab(wbout)], {type: "application/octet-stream"}), filename)
}


export function downloadMapCsv(
	indicator,
	territoriesMetadata,
	downloadContext,
) {
	const { observations } = indicator

	// Compute sorted territory code list
	const territoryCodeSet = new Set()
	observations.forEach((yearObs) => {
		Object.keys(yearObs).forEach((key) => {
			if (key != "Year") {
				territoryCodeSet.add(key)
			}
		})
	})
	const territoryCodeList = [...territoryCodeSet].sort()

	// Filter observations
	const filteredObs = filterObservations(observations, downloadContext)

	// Get all years
	const yearSet = new Set()
	Object.values(filteredObs).forEach((obsObj) => {
		Object.keys(obsObj).forEach((year) => {
			yearSet.add(year)
		})
	})
	const yearList = [...yearSet].sort()

	const headers = ["Code zone", "Nom zone", ...yearList]
	const content = territoryCodeList.map((territoryCode) => {
		const yearObs = yearList.map((year) =>
			filteredObs[territoryCode] && filteredObs[territoryCode][year]
				? filteredObs[territoryCode][year]
				: NA,
		)
		return [
			territoryCode,
			territoriesMetadata.getTerritoryName(territoryCode) || "",
			...yearObs,
		]
	})

	// Build tabular data
	const data = [headers, ...content]

	// Output Excel data
	const filename = computeDownloadFilename(indicator, downloadContext, ".xlsx")
	saveXlsxAs(data, filename)
}

function s2ab(s) {
	var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
	var view = new Uint8Array(buf);  //create uint8array as viewer
	for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
	return buf;
}

// https://stackoverflow.com/a/23749355/3042306
function getAbsoluteHeight(el) {
	// Get the DOM Node if you pass in a string
	el = typeof el === "string" ? document.querySelector(el) : el

	var styles = window.getComputedStyle(el)
	var margin =
		parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"])

	return Math.ceil(el.offsetHeight + margin)
}

export function downloadChartCsv(indicator, territoriesMetadata) {
	const allPeriods = arrayUnique(
		Object.values(indicator.observationsByTerritoryCode).flatMap(
			(observations) => observations.period,
		),
	).sort()

	const header = ["Code zone", "Nom zone", ...allPeriods]

	const rows = Object.entries(indicator.observationsByTerritoryCode).map(
		([territoryCode, observations]) => {
			const valueByPeriod = observations.period.reduce((acc, period, index) => {
				acc[period] = observations.value[index]
				return acc
			}, {})
			return [
				territoryCode,
				territoriesMetadata.getTerritoryName(territoryCode) || "",
				...allPeriods.map((period) => valueByPeriod[period]),
			]
		},
	)

	const data = [header, ...rows]
	const territoryCodes = Object.keys(indicator.observationsByTerritoryCode)

	// Output Excel data
	const filename = `${indicator.name} - ${territoryCodes.join(", ")}.xlsx`
	saveXlsxAs(data, filename)
}
