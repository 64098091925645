export const navLinks = [
	{
		routeName: "mapsIndex",
		pageName: "maps",
		text: "Cartes",
		position: "left",
	},
	{
		routeName: "chartsIndex",
		pageName: "charts",
		text: "Graphiques",
		position: "left",
	},
	{
		routeName: "about",
		pageName: "about",
		text: "À propos",
		position: "right",
	},
]
