<script>
	import clsx from "clsx"
	import { createEventDispatcher } from "svelte"
	import { page, url } from "@sveltech/routify"
	import { navLinks } from "~/nav.js"
	const dispatch = createEventDispatcher()
</script>

<ul>
	{#each navLinks as { routeName, pageName, text } (routeName)}
		<li>
			<a
				class={clsx('block text-africartes-gray py-1 border-left-toggle', $page.meta.name === pageName && 'selected')}
				href={$url(routeName)}
				on:click|preventDefault={() => {
					dispatch('click', routeName)
				}}>
				{text}
			</a>
		</li>
	{/each}
</ul>
