<script>
	export let indicator
	export let twoColumnLayout = false
	export let width

	import clsx from "clsx"
	import { download } from "~/heroicons.js"
	import { CHART_TYPE } from "~/indicator.js"
	import { territoriesMetadata } from "~/stores.js"
	import IndicatorSource from "~/components/IndicatorSource.svelte"
	import IndicatorTooltip from "~/components/IndicatorTooltip.svelte"
	import SvgIcon from "~/components/SvgIcon.svelte"
	import { downloadChartCsv } from "~/components/charts/download.js"
	import IndicatorChart from "~/components/charts/IndicatorChart.svelte"

	$: territoryCodes = Object.keys(indicator.observationsByTerritoryCode)

	const indicatorChartMargin = 30

	function formatChartTitle({ long_name, unit }) {
		let chartTitle = long_name
		if (unit) {
			chartTitle += ` – ${unit}`
		}
		return chartTitle
	}
</script>

<div
	class={clsx('card', indicator.chartType === CHART_TYPE.PYRAMID && ['relative', 'overflow-x-auto', twoColumnLayout && 'col-span-2'])}>
	<div class="flex justify-end">
		<IndicatorTooltip indicatorDescription={indicator.description} />
		<button
			class="text-gray-500 hover:text-black"
			title="Télécharger au format Excel (xlsx)"
			on:click|preventDefault={() => downloadChartCsv(indicator, $territoriesMetadata)}>
			<SvgIcon size={20} {...download} />
		</button>
	</div>
	<div class="mb-4">
		<IndicatorChart
			{...indicator}
			title={formatChartTitle(indicator)}
			width={(twoColumnLayout ? width / 2 : width) - indicatorChartMargin} />
	</div>
	{#if indicator.source}
		<div class="flex justify-end text-gray-500">
			<IndicatorSource
				country={territoryCodes.length === 1 ? territoryCodes[0] : null}
				source={indicator.source} />
		</div>
	{/if}
</div>
