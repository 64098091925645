import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "~/../tailwind.config.js"

const { theme: tailwindTheme } = resolveConfig(tailwindConfig)

export function getBreakpoint(name) {
	const breakpointString = tailwindTheme.screens[name]
	if (!breakpointString.endsWith("px")) {
		throw new Error("breakpoints are only supported when their unit is px")
	}
	return parseInt(breakpointString)
}
