<script>
	import promiseAllProperties from "promise-all-properties"
	import { goto, params } from "@sveltech/routify"
	import { fetchIndicatorForArea, fetchTopology } from "~/api.js"
	import config from "~/config.js"
	import { CHART_TYPE } from "~/indicator.js"
	import { indicatorsMetadata, loading, showSideBar } from "~/stores.js"
	import { slugsFromUrlParam, slugsToUrlParam } from "~/url.js"
	import ComparableMap from "~/components/ComparableMap.svelte"
	import IndicatorPicker from "~/components/IndicatorPicker.svelte"
	import SideBarLayout from "~/components/SideBarLayout.svelte"

	// Do not use areaSlug property because it is not in sync with $params.indicatorSlug
	// Cf https://routify.dev/guide/introduction/parameters
	$: areaSlugs = slugsFromUrlParam($params.areaSlug, { allowDuplicates: true })
	$: compareMode = areaSlugs.length > 1

	let indicators = []
	let topologies = []
	let fetchDataError

	function redirectOrFetchData(indicatorsMetadata, indicatorSlug, areaSlugs) {
		for (const areaSlug of areaSlugs) {
			const indicatorMetadata = indicatorsMetadata.find(
				({ slug }) => slug === indicatorSlug,
			)
			if (
				indicatorMetadata &&
				!indicatorMetadata.area_slugs.includes(areaSlug)
			) {
				$goto("/")
				return
			}
		}
		fetchData(indicatorSlug, areaSlugs)
	}

	async function fetchData(indicatorSlug, areaSlugs) {
		const promises = {
			topology0: fetchTopology(areaSlugs[0]),
			indicator0: fetchIndicatorForArea(indicatorSlug, areaSlugs[0]),
		}
		const areDifferentAreas = compareMode && areaSlugs[0] !== areaSlugs[1]
		if (areDifferentAreas) {
			promises.topology1 = fetchTopology(areaSlugs[1])
			promises.indicator1 = fetchIndicatorForArea(indicatorSlug, areaSlugs[1])
		}
		let data
		indicators = []
		topologies = []
		fetchDataError = null
		try {
			data = await loading.wrap(promiseAllProperties(promises))
		} catch (error) {
			fetchDataError = error
			throw error
		}
		topologies = [data.topology0]
		indicators = [data.indicator0]
		if (compareMode) {
			indicators.push(areDifferentAreas ? data.indicator1 : data.indicator0)
			topologies.push(areDifferentAreas ? data.topology1 : data.topology0)
		}
	}

	$: if (
		$params.indicatorSlug &&
		areaSlugs.length > 0 &&
		$indicatorsMetadata.length > 0
	) {
		redirectOrFetchData($indicatorsMetadata, $params.indicatorSlug, areaSlugs)
	}

	function handleIndicatorSelect(event) {
		$showSideBar = false
		$goto("./", { indicatorSlug: slugsToUrlParam(event.detail) })
	}
</script>

<!-- routify:options name="maps" -->

<svelte:head>
	{#if indicators.length > 0}
		<title>{indicators[0].long_name} / {config.appTitle}</title>
	{/if}
</svelte:head>

<SideBarLayout>
	<div slot="sidebar-rest">
		<IndicatorPicker
			excludeChartTypes={[CHART_TYPE.PYRAMID]}
			on:select={handleIndicatorSelect}
			selectedValues={[$params.indicatorSlug]} />
	</div>
	{#if fetchDataError}
		<div class="alert">
			Impossible de charger les données servant à afficher la carte.
		</div>
	{:else if indicators.length > 0}
		<ComparableMap {areaSlugs} {indicators} {topologies} />
	{/if}
</SideBarLayout>
