import { remove as removeDiacritics } from "diacritics"

export function arrayDifference(arrA, arrB) {
	return arrA.filter((x) => !arrB.includes(x))
}

export function arrayUnique(arr) {
	return [...new Set(arr)]
}

export function debounce(f, delay = 50) {
	let timerId
	return function () {
		if (timerId) {
			clearTimeout(timerId)
		}
		timerId = setTimeout(f, delay)
	}
}

export function indexBy(object, propertyName, f) {
	return object.reduce((acc, cur) => {
		acc[cur[propertyName]] = f ? f(cur) : cur
		return acc
	}, {})
}

export function mapValues(object, f) {
	let result = {}
	for (const [k, v] of Object.entries(object)) {
		result[k] = f(v)
	}
	return result
}

export function comparator(a, b) {
	return a > b ? 1 : b > a ? -1 : 0
}

export function sanitizeString(s) {
	return removeDiacritics(s).toLowerCase().trim()
}

export function sortBy(propertyName) {
	return (a, b) =>
		comparator(sanitizeString(a[propertyName]), sanitizeString(b[propertyName]))
}
