// From https://heroicons.dev/

export const download = {
	d:
		"M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z",
	viewBox: "0 0 20 20",
	variant: "solid",
}

export const externalLink = {
	d:
		"M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14",
	viewBox: "0 0 24 24",
	variant: "outline",
}

export const information = {
	d: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
	viewBox: "0 0 24 24",
	variant: "outline",
}

export const menu = {
	d: "M4 6h16M4 12h16M4 18h16",
	viewBox: "0 0 24 24",
	variant: "outline",
}

export const pause = {
	d: "M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z",
	viewBox: "0 0 24 24",
	variant: "outline",
}

export const play = {
	d:
		"M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
	viewBox: "0 0 24 24",
	variant: "outline",
}

export const trash = {
	d:
		"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16",
	viewBox: "0 0 24 24",
	variant: "outline",
}

export const x = {
	d: "M6 18L18 6M6 6l12 12",
	viewBox: "0 0 24 24",
	variant: "outline",
}
