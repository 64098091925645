<script>
	// SVG extracted from https://zoranravic.com/svg-textures/ playground (copy-pasted from DOM inspector).

	export let id
	export let stroke
	export let strokeWidth = 2
</script>

<defs>
	<pattern {id} patternUnits="userSpaceOnUse" width="6" height="6">
		<path
			{stroke}
			stroke-width={strokeWidth}
			shape-rendering="auto"
			stroke-linecap="square"
			d="M0,6l6,-6M-1.5,1.5l3,-3M4.5,7.5l3,-3" />
	</pattern>
</defs>
