<script>
	export let selectedValues = null

	import config, { AFRICA_SLUG, WORLD_SLUG } from "~/config.js"
	import { territoriesMetadata } from "~/stores.js"
	import CategorizedButtons from "~/components/CategorizedButtons.svelte"
	import Modal from "~/components/modals/Modal.svelte"

	const labelByAreaSlug = {
		[AFRICA_SLUG]: "Pays d'Afrique",
		[WORLD_SLUG]: "Autres pays",
	}

	const categories = config.areas
		.map((area) => {
			const territories = $territoriesMetadata.areas[area.slug].map(
				(territoryCode) => ({
					id: territoryCode,
					label: $territoriesMetadata.getTerritoryName(territoryCode),
				}),
			)
			return territories.length > 0
				? {
						id: area.slug,
						label: labelByAreaSlug[area.slug] || area.label,
						buttons: territories,
						open: area.slug !== WORLD_SLUG,
				  }
				: null
		})
		.filter(Boolean)
</script>

<Modal on:close zIndex={30}>
	<div slot="header">Sélectionner une ou plusieurs zones géographiques</div>
	<CategorizedButtons {categories} {selectedValues} multiple on:select />
</Modal>
