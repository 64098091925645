export const AFRICA_SLUG = "africa"
export const AFRICA_DR_SLUG = "africa_dr"
export const WORLD_SLUG = "world"

export const FIRST_COUNTRY_CODE = "ZAF"
export const TOUT_AFRIQUE_SLUG = "AF"

const areas = [
	{ slug: AFRICA_SLUG, label: "Afrique" },
	{ slug: AFRICA_DR_SLUG, label: "Afrique – directions régionales" },
	{ slug: WORLD_SLUG, label: "Monde" },
]

function getArea(slug) {
	const area = areas.find((area) => area.slug === slug)
	if (!area) {
		throw new Error(`ENABLED_AREAS references an invalid area slug: ${slug}`)
	}
	return area
}

const enabledAreas = process.env.ENABLED_AREAS
	? process.env.ENABLED_AREAS.split(":").map(getArea)
	: areas

export default {
	apiBaseUrl: process.env.API_BASE_URL || "https://api.africartes.fr/2",
	appTitle: process.env.APP_TITLE || "L'Afrique en cartes",
	areas: enabledAreas,
}
