// From https://dev.to/jsmitar/svelte-creating-dialog-component-with-use-portal-3hp4

function moveNode(node, target) {
	if (typeof target === "string") {
		target = document.getElementById(target)
	}
	if (!target) target = document.body

	target.appendChild(node)
}

function portal(node, target) {
	moveNode(node, target)

	return {
		update(target) {
			moveNode(node, target)
		},
	}
}

export default portal
