<script>
	export let feature
	export let generatePath
	export let stripesId

	import { getContext, tick } from "svelte"
	import { createPopperActions } from "svelte-popperjs"

	const { featureTooltip } = getContext("Map")

	// Detect touch devices based on actual touch events, instead of responsive screen width,
	// to allow small window sizes on desktop using a real mouse.
	let isTouchDevice = false

	function handleClick(event) {
		if (isTouchDevice) {
			event.preventDefault()
		}
	}

	function handleMouseEnter() {
		if (isTouchDevice) {
			return
		}
		showTooltip()
	}

	function handleMouseLeave() {
		if (isTouchDevice) {
			return
		}
		hideTooltip()
	}

	function handleTouchStart() {
		isTouchDevice = true
		showTooltip()
	}

	const [popperRef, popperContent] = createPopperActions()

	async function showTooltip() {
		// Force Popper JS to reposition the tooltip correctly.
		hideTooltip()
		await tick()

		$featureTooltip = { popperContent, feature }
	}

	function hideTooltip() {
		$featureTooltip = null
	}
</script>

<path
	use:popperRef
	d={generatePath(feature)}
	fill={feature.properties.crosshatched ? `url(#${stripesId})` : feature.properties.color}
	class="feature-path"
	on:mouseenter={handleMouseEnter}
	on:mouseleave={handleMouseLeave}
	on:touchstart={handleTouchStart}
	on:click={handleClick} />

<style>
	.feature-path {
		stroke: #fff;
		stroke-width: 0.5px;
	}

	.feature-path:hover {
		fill-opacity: 0.5;
		stroke-width: 2px;
	}
</style>
