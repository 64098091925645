<script>
	import { redirect } from "@sveltech/routify"
	import { indicatorsMetadata } from "~/stores.js"

	let redirected = false
	$: if (!redirected && $indicatorsMetadata.length > 0) {
		redirected = true
		$redirect("mapsIndicatorIndex", {
			indicatorSlug: $indicatorsMetadata[0].slug,
		})
	}
</script>

<!-- routify:options name="mapsIndex" -->
