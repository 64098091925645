<script>
	export let chartType
	export let observationsByTerritoryCode
	export let title
	export let width

	import { CHART_TYPE, NA } from "~/indicator.js"
	import { territoriesMetadata } from "~/stores.js"
	import Plotly from "./Plotly.svelte"
	import Pyramid from "./pyramid/Pyramid.svelte"

	$: cleanObservations =
		chartType === CHART_TYPE.LINE &&
		Object.entries(observationsByTerritoryCode).reduce(
			(acc, [territoryCode, observations]) => {
				let period = []
				let value = []
				for (const [index, currentValue] of observations.value.entries()) {
					if (currentValue === NA) {
						continue
					}
					value.push(currentValue)
					period.push(observations.period[index])
				}
				acc[territoryCode] = { value, period }
				return acc
			},
			{},
		)

	$: hasData =
		chartType === CHART_TYPE.LINE
			? Object.values(cleanObservations).some(
					(observations) => observations.period.length > 0,
			  )
			: Object.values(observationsByTerritoryCode).some(
					(observations) => Object.keys(observations).length > 0,
			  )

	$: lineChartData =
		chartType === CHART_TYPE.LINE &&
		Object.entries(cleanObservations).map(([territoryCode, observations]) => ({
			x: observations.period,
			y: observations.value,
			name: $territoriesMetadata.getTerritoryName(territoryCode),
			type: "scatter",
			connectgaps: true,
		}))

	let layout
	let multilineTitle = title
	let node
	let numberOfLinesTitle = 1
	let svgTitleWidth
	let svgWidth

	function splitTitle(title, numberOflines) {
		const lineLenght = Math.ceil(title.length / numberOflines)
		let res = ""
		let lineStart = 0
		let lineEnd = lineLenght
		let indexLine = 0
		let line
		while (indexLine < numberOflines) {
			while (title[lineEnd] !== " ") {
				console.log(title[lineEnd])
				lineEnd++
			}
			// found end of current word, add line
			line = title.substring(lineStart, lineEnd + 1)
			if (indexLine < numberOflines - 1) {
				line += "<br>"
			}
			res += line
			indexLine++
			lineStart = lineEnd + 1
			lineEnd = lineStart + lineLenght
			if (lineEnd >= title.length - 1) {
				line = title.substring(lineStart, lineEnd)
				res += line
				break
			}
		}
		return res
	}

	$: if (node) {
		svgWidth = node.clientWidth
		svgTitleWidth = node.getElementsByClassName("g-gtitle")[0].getBBox().width
		if (svgTitleWidth > svgWidth) {
			numberOfLinesTitle = Math.ceil(svgTitleWidth / svgWidth)
			multilineTitle = splitTitle(title, numberOfLinesTitle)
		}
	}

	$: layout = {
		margin: {
			t: 32 * numberOfLinesTitle,
			l: 32,
			r: 32,
		},
		showlegend: true,
		legend: {
			orientation: "h",
		},
		modebar: {
			orientation: "v",
		},
		width,
		title: multilineTitle,
	}

	$: config = {
		toImageButtonOptions: {
			filename: title,
		},
	}
</script>

{#if !hasData}
	<div class="flex h-full items-center justify-center border-2 rounded">
		Aucune donnée disponible
	</div>
{:else if chartType === CHART_TYPE.LINE}
	<div bind:this={node}>
		<Plotly data={lineChartData} {layout} {config} />
	</div>
{:else if chartType === CHART_TYPE.PYRAMID}
	{#each Object.entries(observationsByTerritoryCode) as [territoryCode, { observations, legend }], index}
		{#if index > 0}
			<hr class="mt-8 mb-4" />
		{/if}
		<div class="mb-4">
			{$territoriesMetadata.getTerritoryName(territoryCode)}
		</div>
		<Pyramid {observations} {legend} />
	{/each}
{/if}
