import produce from "immer"

export const CHART_TYPE = {
	PYRAMID: "pyramid",
	LINE: "line",
}

export const DATA_SCOPE = {
	YEARS: "years",
	OLDEST_YEARS: "oldest_years",
	NEWEST_YEARS: "newest_years",
}
export const INF = "Inf"
export const NA = "NA"
export const YEAR = "Year"

export const POPULATION_PYRAMID_INDICATOR_SLUG = "pop_pyramid"

const NUMBER_FORMAT_FR = new Intl.NumberFormat("fr", {
	maximumFractionDigits: 2,
})

export function withPopulationPyramid(indicators) {
	const popCategorySlug = "pop"

	const index = indicators.findIndex(
		(indicator) => indicator.category_slug === popCategorySlug,
	)

	// Insert population pyramid as first indicator having category_slug = "pop".
	return produce(indicators, (draft) => {
		draft.forEach((indicator) => {
			indicator.chartType = CHART_TYPE.LINE
		})
		draft.splice(index, 0, {
			chartType: CHART_TYPE.PYRAMID,
			area_slugs: ["africa"],
			category_slug: popCategorySlug,
			long_name: "Pyramide des âges",
			name: "Pyramide des âges",
			slug: POPULATION_PYRAMID_INDICATOR_SLUG,
		})
	})
}

export const SCALE_CONTINUOUS = "continuous"
export const SCALE_DISCRETE = "discrete"

export function buildMapGeoJson(topology, indicator, year) {
	return produce(topology, (draft) => {
		for (const feature of draft.features) {
			const { properties } = feature
			const { code } = properties
			feature.id = code
			const { observations } = indicator
			const yearObservations =
				observations.find((record) => record[YEAR] === year) || {}
			let value = yearObservations[code]
			if (!value && value !== 0) {
				value = NA
			}
			properties.color = getColor(value, indicator)
			properties.value = value
			properties.year = yearObservations.Year
		}
	})
}

export function buildMapGeoJsonOldestYears(topology, indicator, year1, year2) {
	return produce(topology, (draft) => {
		for (const feature of draft.features) {
			const { properties } = feature
			const { code } = properties
			feature.id = code

			const { observations } = indicator
			let yearObservations = {}
			for (let observation of observations) {
				if (observation.Year >= year1 && observation.Year <= year2) {
					if (observation[code]) {
						yearObservations = observation
						break
					}
				}
				if (observation.Year > year2) {
					break
				}
			}
			let value = yearObservations[code]
			if (!value && value !== 0) {
				value = NA
			}
			properties.color = getColor(value, indicator)
			properties.value = value
			properties.year = yearObservations.Year
		}
	})
}

export function buildMapGeoJsonNewestYears(topology, indicator, year1, year2) {
	return produce(topology, (draft) => {
		for (const feature of draft.features) {
			const { properties } = feature
			const { code } = properties
			feature.id = code

			const observations = [...indicator.observations].reverse()
			let yearObservations = {}
			for (let observation of observations) {
				if (observation.Year >= year1 && observation.Year <= year2) {
					if (observation[code]) {
						yearObservations = observation
						break
					}
				}
				if (observation.Year < year1) {
					break
				}
			}
			let value = yearObservations[code]
			if (!value && value !== 0) {
				value = NA
			}
			properties.color = getColor(value, indicator)
			properties.value = value
			properties.year = yearObservations.Year
		}
	})
}

export function formatScaleValue(value, { scale, unit }) {
	switch (scale.type) {
		case SCALE_CONTINUOUS: {
			if (value === NA) {
				return NA
			}
			let formattedValue = NUMBER_FORMAT_FR.format(value)
			if (unit) {
				formattedValue += ` ${unit}`
			}
			return formattedValue
		}
		case SCALE_DISCRETE: {
			const bracket = scale.brackets.find((bracket) => bracket.value === value)
			return bracket ? bracket.label : value
		}
		default:
			throw new Error(`Unsupported scale type: ${scale.type}`)
	}
}

export function getYearsWithAnyValue(observations) {
	return observations
		.filter(({ [YEAR]: ignored, ...values }) =>
			Object.values(values).some((value) => value !== NA),
		)
		.map((record) => record[YEAR])
		.sort()
}

function getContinuousScaleColor(value, scale) {
	for (const { min, max, color } of scale.brackets) {
		if (value > min && value <= max) {
			return color
		}
	}
}

function getDiscreteScaleColor(value, scale) {
	const bracket = scale.brackets.find((bracket) => bracket.value === value)
	return bracket ? bracket.color : null
}

export function getColor(value, { scale, na_color }) {
	if (value === NA) {
		return na_color
	}

	let color
	switch (scale.type) {
		case SCALE_CONTINUOUS:
			color = getContinuousScaleColor(value, scale)
			break
		case SCALE_DISCRETE:
			color = getDiscreteScaleColor(value, scale)
			break
		default:
			throw new Error(`Unsupported scale type: ${scale.type}`)
	}
	return color || na_color
}

function parseInfiniteOrKeep(value) {
	if (value === INF) {
		return Infinity
	} else if (value === `-${INF}`) {
		return -Infinity
	}
	return value
}

export function decodeIndicator(indicator) {
	return produce(indicator, (draft) => {
		const { scale } = draft
		for (const bracket of scale.brackets) {
			if (scale.type === SCALE_CONTINUOUS) {
				bracket.min = parseInfiniteOrKeep(bracket.min)
				bracket.max = parseInfiniteOrKeep(bracket.max)
			}
			bracket.color = `#${bracket.color}`
		}
		draft.na_color = `#${draft.na_color}`
	})
}
