<script>
	export let zIndex = 10

	// From https://svelte.dev/examples#modal

	import { fade, scale } from "svelte/transition"
	import { createEventDispatcher, onDestroy } from "svelte"
	import { beforeUrlChange } from "@sveltech/routify"
	import { x } from "~/heroicons.js"
	import portal from "~/actions/portal.js"
	import SvgIcon from "~/components/SvgIcon.svelte"

	$beforeUrlChange(() => {
		close()
		return true
	})

	const dispatch = createEventDispatcher()

	function close() {
		dispatch("close")
	}

	let modal

	const handle_keydown = (e) => {
		if (e.key === "Escape") {
			close()
			return
		}

		if (e.key === "Tab") {
			// trap focus
			const nodes = modal.querySelectorAll("*")
			const tabbable = Array.from(nodes).filter((n) => n.tabIndex >= 0)

			let index = tabbable.indexOf(document.activeElement)
			if (index === -1 && e.shiftKey) index = 0

			index += tabbable.length + (e.shiftKey ? -1 : 1)
			index %= tabbable.length

			tabbable[index].focus()
			e.preventDefault()
		}
	}

	const previously_focused =
		typeof document !== "undefined" && document.activeElement

	document.body.classList.add("overflow-hidden")

	onDestroy(() => {
		document.body.classList.remove("overflow-hidden")
		if (previously_focused) {
			previously_focused.focus()
		}
	})
</script>

<svelte:window on:keydown={handle_keydown} />

<div use:portal>
	<div
		class="modal-background"
		style={`z-index: ${zIndex};`}
		on:click={close}
		transition:fade />
	<div
		class="modal"
		style={`z-index: ${zIndex + 1};`}
		role="dialog"
		aria-modal="true"
		bind:this={modal}
		transition:scale>
		<div class="flex justify-between items-start">
			<h1 class="text-2xl">
				<slot name="header" />
			</h1>
			<!-- svelte-ignore a11y-autofocus -->
			<button autofocus on:click={close}>
				<SvgIcon size={16} {...x} />
			</button>
		</div>
		<hr class="mb-4" />
		<slot />
	</div>
</div>

<style>
	.modal-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
	}

	.modal {
		position: absolute;
		left: 50%;
		top: 50%;
		width: calc(100vw - 2em);
		max-width: 90%;
		height: calc(100vh - 4em);
		overflow: auto;
		transform: translate(-50%, -50%);
		padding: 1em;
		border-radius: 0.2em;
		background: white;
	}
</style>
