<script>
	export let country = null
	export let source

	import queryString from "query-string"
	import { externalLink } from "~/heroicons.js"
	import { sourcePrefix } from "~/components/charts/map/legend.js"
	import SvgIcon from "./SvgIcon.svelte"

	function dbnomicsUrl(
		{ provider_code, dataset_code, series_codes, dimension_filter },
		country,
	) {
		let url = `https://db.nomics.world/${provider_code}/${dataset_code}`
		if (country) {
			url += `/${series_codes[country]}`
		}
		return queryString.stringifyUrl({
			url,
			query:
				!country && dimension_filter
					? { dimensions: JSON.stringify(dimension_filter) }
					: {},
		})
	}
</script>

<span>
	{sourcePrefix} : {source.name}
	{#if source.type === 'dbnomics'}
		<a
			class="text-gray-500 hover:text-gray-700 do-not-print"
			href={dbnomicsUrl(source, country)}
			target="_blank"
			rel="noopener"
			style="position: relative; top: -2px;"
			title="Voir sur DBnomics">
			<SvgIcon size={17} {...externalLink} />
		</a>
	{/if}
</span>
