<script>
	export let data
	export let config = null
	export let layout = null

	function action(node, { data, layout, config }) {
		window.Plotly.newPlot(node, data, layout, config)
		return {
			update({ data, layout, config }) {
				window.Plotly.react(node, data, layout, config)
			},
			destroy() {
				window.Plotly.purge(node)
			},
		}
	}
</script>

<div use:action={{ data, layout, config }} />
