<script>
	export let value = null

	import Select from "svelte-select"
	import { DATA_SCOPE } from "~/indicator.js"

	const items = [
		{ value: DATA_SCOPE.YEARS, label: "Année fixe" },
		{ value: DATA_SCOPE.OLDEST_YEARS, label: "Valeurs les plus anciennes" },
		{ value: DATA_SCOPE.NEWEST_YEARS, label: "Valeurs les plus récentes" },
	]

	$: selectedValue = items.find((item) => item.value === value)

	function getSelectionLabel(option) {
		if (option) return "Période: " + option.label
	}
</script>

<Select
	{items}
	{getSelectionLabel}
	bind:selectedValue
	on:select={(event) => {
		value = event.detail.value
	}} />
