<script>
	import { redirect } from "@sveltech/routify"
	import { fetchIndicatorsByTerritories } from "~/api.js"
	import { FIRST_COUNTRY_CODE } from "~/config.js"
	import { indicatorsMetadata, loading } from "~/stores.js"
	import { slugsToUrlParam } from "~/url.js"

	loading
		.wrap(fetchIndicatorsByTerritories([FIRST_COUNTRY_CODE]))
		.then((data) => {
			const indicatorSlugs = Object.keys(data)
			if (indicatorSlugs.length > 0) {
				const indicatorSlugsOfFirstCategory = $indicatorsMetadata
					.filter((indicatorMetadata) => {
						const isInCategory =
							indicatorMetadata.category_slug ==
							$indicatorsMetadata[0].category_slug
						const isDefinedForCountry = indicatorSlugs.includes(
							indicatorMetadata.slug,
						)
						return isInCategory && isDefinedForCountry
					})
					.map((indicatorMetadata) => indicatorMetadata.slug)

				$redirect("charts", {
					territoryCode: slugsToUrlParam([FIRST_COUNTRY_CODE]),
					indicatorSlug: slugsToUrlParam(indicatorSlugsOfFirstCategory),
				})
			}
		})
</script>

<!-- routify:options name="chartsIndex" -->
