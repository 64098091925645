<script>
	export let areaSlug
	export let data
	export let heightWriter = false
	export let selectedYear

	import { getContext, tick } from "svelte"
	import { LayerCake } from "layercake"
	import { isDesktop } from "~/stores.js"
	import MapSvg from "./MapSvg.svelte"
	import Svg from "~/components/Svg.svelte"

	const { mapHeight } = getContext("ComparableMap")

	let node

	let blockResize = false

	let resizedOnMobile = false

	let style = ""
	let viewBox = undefined

	async function handleWindowResize() {
		// resize only once on mobile; on start
		if (!$isDesktop) {
			if (resizedOnMobile) {
				return
			}
			resizedOnMobile = true
		}
		if (!heightWriter || blockResize) {
			return
		}
		await tick()
		const documentHeight = document.documentElement.clientHeight
		const y = node.getBoundingClientRect().y
		const margin = 10
		$mapHeight = documentHeight - y - margin

		if (areaSlug === "world" && node?.clientWidth) {
			const width = node.clientWidth
			style = "overflow: hidden;transform: scale(1.1);"
			const offsetX = Math.round(width / 9.06)
			const viewBoxWidth = Math.round(width / 1.053)
			const translateX = Math.round(width / 45)
			viewBox = `${offsetX} 0 ${viewBoxWidth} ${$mapHeight}`
			style += `translate: ${translateX}px`
		} else {
			style = ""
			viewBox = undefined
		}
	}

	$: handleWindowResize()
</script>

<svelte:window on:resize={handleWindowResize} />

<div bind:this={node}>
	<div style={$mapHeight && `height: ${$mapHeight}px;`}>
		<LayerCake {data} containerHeight={$mapHeight}>
			<Svg {viewBox} {style}>
				<MapSvg {selectedYear} />
			</Svg>
		</LayerCake>
	</div>
</div>
