<script>
	import config from "~/config.js"
	import { isDesktop, showSideBar } from "~/stores.js"
	import { getBreakpoint } from "~/theme.js"
	import NavBar from "~/components/NavBar.svelte"

	let innerWidth = 0
	const smBreakpoint = getBreakpoint("sm")
	$: $isDesktop = innerWidth >= smBreakpoint
</script>

<svelte:window bind:innerWidth />

<svelte:head>
	<title>{config.appTitle}</title>
</svelte:head>

<NavBar
	on:menu-toggle={() => {
		$showSideBar = !$showSideBar
	}} />

<main class="h-full pt-16">
	<slot />
</main>
