<script>
	export let legend
	export let naColor
	export let scale

	import { NA } from "~/indicator.js"
	import { formatScale } from "./legend.js"

	$: displayedBrackets = scale.brackets
		.map((bracket) => ({
			...bracket,
			formattedScale: formatScale(
				scale,
				bracket.label,
				bracket.min,
				bracket.max,
			),
		}))
		.concat({ formattedScale: "NA", color: naColor })
</script>

<div>
	<div class="flex flex-wrap whitespace-no-wrap text-gray-800 justify-center">
		{#each displayedBrackets as { color, label, min, max, formattedScale }}
			<div class="flex items-center hover:bg-gray-200 rounded px-2">
				<div class="color-square mr-2" style="background-color: {color}" />
				<span>{formattedScale}</span>
			</div>
		{/each}
	</div>
	<div class="ml-2 text-center">{legend}</div>
</div>

<style lang="postcss">
	.color-square {
		@apply flex-shrink-0 border border-gray-500 w-3 h-3 rounded;
	}
</style>
