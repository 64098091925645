<script>
	export let multiple = false
	export let selectedValues = null
	export let excludeChartTypes = null

	import clsx from "clsx"
	import { createEventDispatcher } from "svelte"
	import { indicatorCategories, indicatorsMetadata } from "~/stores.js"
	import { sanitizeString } from "~/utils.js"

	const dispatch = createEventDispatcher()

	let textFilter = ""

	function findIndicatorsOfCategory(categorySlug, indicatorsMetadata) {
		return indicatorsMetadata.filter(
			(indicator) => indicator.category_slug === categorySlug,
		)
	}

	// Do not sort categories because they are given in a defined order, but sort indicators by name inside each category.
	let displayedCategories = []
	$: displayedCategories = $indicatorCategories
		.map((category) => {
			// Define isSelected property for each indicator and sort indicators by name, to ease template writing.
			const indicators = findIndicatorsOfCategory(
				category.slug,
				$indicatorsMetadata,
			).map((indicator) => ({
				...indicator,
				isSelected: selectedValues && selectedValues.includes(indicator.slug),
			}))

			const newCategory = {
				...category,
				indicators,
				allSelected: indicators.every((indicator) => indicator.isSelected),
				anySelected: indicators.some((indicator) => indicator.isSelected),
			}

			// Apply search-as-you-type filter.
			const textFilterClean = sanitizeString(textFilter)
			if (textFilter) {
				const filteredIndicators = indicators.filter(
					(indicator) =>
						!textFilterClean ||
						sanitizeString(indicator.name).search(textFilterClean) !== -1,
				)
				if (filteredIndicators.length === 0) {
					return null
				}
				newCategory.indicators = filteredIndicators
			}

			return newCategory
		})
		.filter(Boolean)

	function selectValue(indicatorSlug) {
		selectedValues = [...(selectedValues || []), indicatorSlug]
	}

	function deselectValue(indicatorSlug) {
		selectedValues = selectedValues || []
		const index = selectedValues.indexOf(indicatorSlug)
		if (index !== -1) {
			selectedValues = [
				...selectedValues.slice(0, index),
				...selectedValues.slice(index + 1),
			]
		}
	}

	function selectAllInCategory(categorySlug) {
		findIndicatorsOfCategory(categorySlug, $indicatorsMetadata).forEach(
			({ slug }) => {
				selectValue(slug)
			},
		)
		dispatch("select", selectedValues)
	}

	function deselectAllInCategory(categorySlug) {
		findIndicatorsOfCategory(categorySlug, $indicatorsMetadata).forEach(
			({ slug }) => {
				deselectValue(slug)
			},
		)
		dispatch("select", selectedValues)
	}

	function deselectAll() {
		$indicatorsMetadata.forEach(({ slug }) => {
			deselectValue(slug)
		})
		dispatch("select", selectedValues)
	}

	$: anyIndicatorSelected = displayedCategories.some(
		(category) => category.anySelected,
	)
</script>

<div class="mb-4 flex flex-col">
	<input
		class="border-2 h-8 px-2 rounded-full text-gray-900 outline-none
			focus:border-africartes-red"
		type="search"
		placeholder="Rechercher un indicateur..."
		bind:value={textFilter} />
</div>

{#if multiple}
	<div class="flex justify-end space-x-1">
		<button
			class="btn-link"
			disabled={!anyIndicatorSelected}
			on:click|preventDefault={deselectAll}
			title="Désélectionner tous les indicateurs, toutes catégories confondues">
			Tout désélectionner
		</button>
	</div>
{/if}

{#each displayedCategories as category (category.slug)}
	<details class="my-4">
		<summary
			class="hover:text-white text-lg {category.anySelected && !multiple ? 'border-left-toggle selected' : ''}">
			{#if multiple}
				<input
					type="checkbox"
					class="mr-1"
					indeterminate={!category.allSelected && category.anySelected}
					checked={category.allSelected}
					on:change={(event) => {
						if (event.target.checked) {
							selectAllInCategory(category.slug)
						} else {
							deselectAllInCategory(category.slug)
						}
					}} />
			{/if}
			{category.name}
		</summary>
		<ul class={clsx('space-y-1', multiple ? 'ml-6' : 'ml-4')}>
			{#each category.indicators as indicator (indicator.slug)}
				{#if !excludeChartTypes || !excludeChartTypes.includes(indicator.chartType)}
					<li>
						{#if multiple}
							<label class="flex items-center">
								<input
									type="checkbox"
									class="mr-2"
									checked={indicator.isSelected}
									on:change={(event) => {
										const { checked } = event.target
										const { slug } = indicator
										if (checked) {
											selectValue(slug)
										} else {
											deselectValue(slug)
										}
										dispatch('select', selectedValues)
									}} />
								{indicator.name}
							</label>
						{:else}
							<div
								class={clsx('cursor-pointer border-left-toggle', indicator.isSelected && 'selected')}
								on:click={() => {
									dispatch('select', indicator.slug)
								}}>
								{#if indicator.isSelected}
									<div class="sr-only">selected</div>
								{/if}
								{indicator.name}
							</div>
						{/if}
					</li>
				{/if}
			{/each}
		</ul>
	</details>
{/each}

<style lang="postcss">
	.folder-select {
		@apply text-gray-500;
	}

	.folder-select:hover {
		@apply text-africartes-red;
	}
</style>
