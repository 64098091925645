import { get } from "svelte/store"
import { url } from "@sveltech/routify"

const SLUG_SEPARATOR = "+"

function splitSlugs(s) {
	return s
		.split(SLUG_SEPARATOR)
		.map((s) => s.trim())
		.filter(Boolean)
}

function dedupe(values) {
	return [...new Set(values)]
}

export function slugsFromUrlParam(s, { allowDuplicates = false } = {}) {
	if (!s || s === "_") {
		return []
	}
	const slugs = splitSlugs(s)
	return allowDuplicates ? slugs : dedupe(slugs)
}

export function slugsToUrlParam(slugs, { allowDuplicates = false } = {}) {
	if (!Array.isArray(slugs)) {
		slugs = [slugs]
	}
	if (!allowDuplicates) {
		slugs = dedupe(slugs)
	}
	if (slugs.length === 0) return "_"
	return slugs.join(SLUG_SEPARATOR)
}
