<script>
	// Common page shared between "country" and "DR" routes.

	import { goto, params } from "@sveltech/routify"
	import {
		fetchIndicatorsByTerritories,
		fetchPopulationByCountry,
	} from "~/api.js"
	import config, { AFRICA_SLUG } from "~/config.js"
	import { POPULATION_PYRAMID_INDICATOR_SLUG } from "~/indicator.js"
	import {
		indicatorCategories,
		indicatorsMetadata,
		loading,
		territoriesMetadata,
	} from "~/stores.js"
	import { slugsFromUrlParam, slugsToUrlParam } from "~/url.js"
	import { arrayDifference, arrayUnique } from "~/utils.js"
	import IndicatorChartCategory from "~/components/IndicatorChartCategory.svelte"
	import IndicatorChartCard from "~/components/IndicatorChartCard.svelte"
	import IndicatorPicker from "~/components/IndicatorPicker.svelte"
	import SideBarLayout from "~/components/SideBarLayout.svelte"
	import TerritoryPickerModal from "~/components/modals/TerritoryPickerModal.svelte"

	$: indicatorSlugs = slugsFromUrlParam($params.indicatorSlug)
	$: territoryCodes = slugsFromUrlParam($params.territoryCode)

	function isAfricanCountry(territoryCode) {
		return $territoriesMetadata.areas[AFRICA_SLUG].includes(territoryCode)
	}

	let indicatorsBySlug = {}
	let fetchDataError

	async function fetchData(indicatorSlugs, territoryCodes) {
		function addIndicatorsPromiseIfNeeded() {
			const nonPyramidIndicatorSlugs = arrayDifference(indicatorSlugs, [
				POPULATION_PYRAMID_INDICATOR_SLUG,
			])
			if (nonPyramidIndicatorSlugs.length === 0) {
				return false
			}
			const fetchedTerritoryCodes = arrayUnique(
				Object.entries(indicatorsBySlug)
					.filter(([key]) => key !== POPULATION_PYRAMID_INDICATOR_SLUG)
					.map(([, value]) => value)
					.flatMap(Object.keys),
			)
			const newTerritoryCodes = arrayDifference(
				territoryCodes,
				fetchedTerritoryCodes,
			)
			if (newTerritoryCodes.length === 0) {
				return false
			}
			promises.push(fetchIndicatorsByTerritories(newTerritoryCodes))
			return true
		}

		function addPyramidPromisesIfNeeded() {
			if (!indicatorSlugs.includes(POPULATION_PYRAMID_INDICATOR_SLUG)) {
				return false
			}
			const fetchedTerritoryCodes = Object.keys(
				indicatorsBySlug[POPULATION_PYRAMID_INDICATOR_SLUG] || {},
			)
			const newTerritoryCodes = arrayDifference(
				territoryCodes,
				fetchedTerritoryCodes,
			)
			if (newTerritoryCodes.length === 0) {
				return false
			}
			const pyramidPromises = newTerritoryCodes
				.map((territoryCode) => {
					if (isAfricanCountry(territoryCode)) {
						// Reminder: population pyramid data is available only for African countries (not DR, not world-wide countries).
						return fetchPopulationByCountry(territoryCode)
					}
				})
				.filter(Boolean)
			if (pyramidPromises.length === 0) {
				return false
			}
			promises = [...promises, ...pyramidPromises]
			return true
		}

		let promises = []

		const indicatorsFetched = addIndicatorsPromiseIfNeeded()
		const pyramidsFetched = addPyramidPromisesIfNeeded()

		if (promises.length === 0) {
			return
		}

		let data
		fetchDataError = null
		try {
			data = await loading.wrap(Promise.all(promises))
		} catch (error) {
			fetchDataError = error
			throw error
		}

		const indicatorData = indicatorsFetched ? data[0] : null
		const pyramidData = pyramidsFetched
			? indicatorsFetched
				? data.slice(1)
				: data
			: null

		// Merge fetched indicators data to indicatorsBySlug
		if (indicatorData !== null) {
			for (const [indicatorSlug, observationsByTerritory] of Object.entries(
				indicatorData,
			)) {
				for (const [territoryCode, observations] of Object.entries(
					observationsByTerritory,
				)) {
					if (indicatorsBySlug[indicatorSlug]) {
						indicatorsBySlug[indicatorSlug][territoryCode] = observations
					} else {
						indicatorsBySlug[indicatorSlug] = { [territoryCode]: observations }
					}
				}
			}
		}

		// Merge fetched population pyramid data to indicatorsBySlug
		if (pyramidData !== null) {
			for (const populationPyramidData of pyramidData) {
				if (indicatorsBySlug[POPULATION_PYRAMID_INDICATOR_SLUG]) {
					indicatorsBySlug[POPULATION_PYRAMID_INDICATOR_SLUG][
						populationPyramidData.country
					] = populationPyramidData
				} else {
					indicatorsBySlug[POPULATION_PYRAMID_INDICATOR_SLUG] = {
						[populationPyramidData.country]: populationPyramidData,
					}
				}
			}
		}
	}

	$: if (indicatorSlugs.length > 0 && territoryCodes.length > 0) {
		fetchData(indicatorSlugs, territoryCodes)
	}

	$: territoryNames = territoryCodes.map((territoryCode) =>
		$territoriesMetadata.getTerritoryName(territoryCode),
	)

	let displayedCategories = []
	$: if (Object.keys(indicatorsBySlug).length > 0) {
		displayedCategories = $indicatorCategories
			.map((category) => {
				const indicatorsOfCategory = []
				for (const indicatorMetadata of $indicatorsMetadata) {
					const { category_slug, slug } = indicatorMetadata
					// Keep only indicators (metadata) belonging to category.
					if (
						category_slug !== category.slug ||
						!indicatorSlugs.includes(slug)
					) {
						continue
					}
					// Add observations to indicator metadata if they are defined for at least one selected territory.
					const observationsByTerritoryCode = territoryCodes.reduce(
						(acc, territoryCode) => {
							const observations =
								indicatorsBySlug[slug] && indicatorsBySlug[slug][territoryCode]
							if (observations) {
								acc[territoryCode] = observations
							}
							return acc
						},
						{},
					)
					if (Object.keys(observationsByTerritoryCode).length === 0) {
						continue
					}
					indicatorsOfCategory.push({
						...indicatorMetadata,
						observationsByTerritoryCode,
					})
				}
				return indicatorsOfCategory.length > 0
					? { ...category, indicators: indicatorsOfCategory }
					: null
			})
			.filter(Boolean)
	}

	let showTerritoryPickerModal = false

	function handleChangeTerritoryClick() {
		showTerritoryPickerModal = true
	}

	function handleTerritoriesSelect(event) {
		showTerritoryPickerModal = false
		$goto("./", {
			indicatorSlug: slugsToUrlParam(indicatorSlugs),
			territoryCode: slugsToUrlParam(event.detail),
		})
	}

	function handleIndicatorSelect(event) {
		$goto("./", { indicatorSlug: slugsToUrlParam(event.detail) })
	}

	let clientWidth = null
	let twoColumnLayout
	$: if (clientWidth !== null) {
		twoColumnLayout = clientWidth >= 913 // allows having 2 columns with a client width of 1280
	}

	// Display max 3 zones in title
	$: pageTitle =
		territoryNames.length <= 3
			? territoryNames.join(", ")
			: territoryNames.slice(0, 3).join(", ") + ", …"
</script>

<svelte:head>
	<title>Graphiques / {config.appTitle}</title>
</svelte:head>

<!-- routify:options name="charts" -->

<SideBarLayout>
	<div slot="sidebar-rest">
		<IndicatorPicker
			multiple
			selectedValues={indicatorSlugs}
			on:select={handleIndicatorSelect} />
	</div>

	{#if fetchDataError}
		<div class="alert">
			Impossible de charger les données servant à afficher les graphiques.
		</div>
	{:else}
		<div class="flex flex-wrap items-center justify-between mb-8">
			<h1 class="text-4xl">{pageTitle}</h1>
			<button class="btn" on:click={handleChangeTerritoryClick}>
				Zones géographiques
			</button>
		</div>

		<div bind:clientWidth>
			{#if clientWidth !== null}
				{#if indicatorSlugs.length === 0}
					<div
						class="my-16 border-2 border-gray-500 mx-auto px-4 py-3 rounded-md  w-2/3">
						<p class="text-xl text-gray-800 mb-1">
							Sélectionner un ou plusieurs indicateurs
						</p>
						<p class="text-sm text-gray-700">
							Aucun indicateur n'est sélectionné. Ajoutez-en afin d'afficher les
							graphiques correspondants.
						</p>
					</div>
				{:else if territoryCodes.length === 0}
					<div
						class="my-16 border-2 border-gray-500 mx-auto px-4 py-3 rounded-md  w-2/3">
						<p class="text-xl text-gray-800 mb-1">
							Sélectionner une ou plusieurs zones géographiques
						</p>
						<p class="text-sm text-gray-700">
							Aucun territoire n'est sélectionné. Ajoutez-en afin d'afficher les
							graphiques correspondants.
						</p>
					</div>
				{:else}
					{#each displayedCategories as category (category.slug)}
						<IndicatorChartCategory {category} {twoColumnLayout} let:indicators>
							<div class="grid gap-4" class:grid-cols-2={twoColumnLayout}>
								{#each indicators as indicator (indicator.slug)}
									<IndicatorChartCard
										{indicator}
										{twoColumnLayout}
										width={clientWidth} />
								{/each}
							</div>
						</IndicatorChartCategory>
					{/each}
				{/if}
			{/if}
		</div>
	{/if}

	{#if showTerritoryPickerModal}
		<TerritoryPickerModal
			on:close={() => (showTerritoryPickerModal = false)}
			on:select={handleTerritoriesSelect}
			selectedValues={territoryCodes} />
	{/if}
</SideBarLayout>
