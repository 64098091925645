module.exports = {
	future: {
		removeDeprecatedGapUtilities: true,
		purgeLayersByDefault: true,
	},
	purge: {
		content: ["src/**/*.svelte"],
		options: {
			// From https://github.com/tailwindlabs/discuss/issues/254#issuecomment-517918397
			defaultExtractor: (content) => {
				const regExp = new RegExp(/[A-Za-z0-9-_:/]+/g)
				const matchedTokens = []
				let match = regExp.exec(content)
				while (match) {
					if (match[0].startsWith("class:")) {
						matchedTokens.push(match[0].substring(6))
					} else {
						matchedTokens.push(match[0])
					}
					match = regExp.exec(content)
				}
				return matchedTokens
			},
		},
	},
	corePlugins: { container: false },
	theme: {
		extend: {
			colors: {
				// Colors are taken from AFD design guide (PDF file).
				africartes: {
					blue: "#2d2264",
					"blue-dark": "#1A143B",
					gray: "rgb(229, 232, 236)",
					red: "rgb(166, 83, 118)",
				},
			},
		},
	},
	variants: {},
	plugins: [require("@tailwindcss/typography")],
}
