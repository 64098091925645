<script>
	// Inspired from https://dev.to/fayaz/making-a-navigation-drawer-sliding-sidebar-with-tailwindcss-blueprint-581l

	import clsx from "clsx"
	import SplitPane from "@sveltejs/svelte-repl/src/SplitPane.svelte"
	import { goto } from "@sveltech/routify"
	import { isDesktop, showSideBar } from "~/stores.js"
	import SideBarLinks from "~/components/SideBarLinks.svelte"

	export let padding = true

	const sectionClasses = clsx("min-h-full bg-gray-100", { "p-4": padding })
	const asideClasses = clsx("bg-africartes-blue-dark text-gray-100", {
		"p-4": padding,
	})

	// Waiting for https://github.com/sveltejs/svelte/issues/2106 to be released...
	$: slots = arguments[1].$$slots

	$: if ($showSideBar) {
		document.body.classList.add("overflow-hidden")
	} else {
		document.body.classList.remove("overflow-hidden")
	}
</script>

{#if $isDesktop}
	{#if slots['sidebar-rest']}
		<SplitPane type="horizontal" pos={25}>
			<aside slot="a" class={clsx(asideClasses, 'min-h-full')}>
				<slot name="sidebar-rest" />
			</aside>
			<section slot="b" class={sectionClasses}>
				<slot />
			</section>
		</SplitPane>
	{:else}
		<section class={clsx(sectionClasses, 'overflow-auto')}>
			<slot />
		</section>
	{/if}
{:else}
	<aside
		class={clsx(asideClasses, 'z-20 duration-300 ease-in-out transform overflow-auto', $showSideBar ? 'translate-x-0 absolute inset-0 below-navbar' : 'fixed -translate-x-full')}>
		<nav class="mb-6">
			<SideBarLinks
				on:click={(event) => {
					$showSideBar = false
					$goto(event.detail)
				}} />
		</nav>
		<div class="ml-3">
			<slot name="sidebar-rest" />
		</div>
	</aside>
	<section class={sectionClasses}>
		<slot />
	</section>
{/if}

<style>
	/* Used by SplitPane */
	:root {
		--second: gray;
	}

	.below-navbar {
		/* Compensate NavBar height */
		top: 4rem;
	}
</style>
