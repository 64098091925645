<script>
	export let closeButton = false

	import clsx from "clsx"
	import { createEventDispatcher } from "svelte"
	import { x } from "~/heroicons.js"
	import SvgIcon from "~/components/SvgIcon.svelte"

	const dispatch = createEventDispatcher()

	$: classes = clsx(
		closeButton && "relative pr-6",
		`px-2 py-1 border-4 border-gray-500 rounded shadow bg-white`,
	)
</script>

<div class={classes}>
	{#if closeButton}
		<button
			class="absolute right-0 top-0 mr-1"
			on:click={() => {
				dispatch('close')
			}}>
			<SvgIcon size={16} {...x} />
		</button>
	{/if}
	<slot />
</div>
