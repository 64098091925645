import { indexBy } from "~/utils.js"

function areDuplicateSlugs(values) {
	return values.length !== [...new Set(values)].length
}

export function checkCategories(categories) {
	// Check unicity of slugs.
	const slugs = categories.map(({ slug }) => slug)
	if (areDuplicateSlugs(slugs)) {
		throw new Error(`Duplicate slugs found in categories: ${slugs}`)
	}
}

export function checkIndicators(indicators) {
	// Check unicity of slugs.
	const slugs = indicators.map(({ slug }) => slug)
	if (areDuplicateSlugs(slugs)) {
		throw new Error(`Duplicate slugs found in indicators: ${slugs}`)
	}
}

export class TerritoriesMetadata {
	constructor({ areas, territories }) {
		this.areas = areas
		this.territories = territories
		this.territoryByCode = indexBy(territories, "code")
	}

	getTerritoryName(territoryCode) {
		return this.territoryByCode[territoryCode].name
	}
}
