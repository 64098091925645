import buildUrl from "build-url"
import makeFetchRetry from "fetch-retry"
import config from "~/config.js"
import { decodeIndicator } from "~/indicator.js"

function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response
	} else {
		const error = new Error(response.statusText)
		error.response = response
		throw error
	}
}

const fetchRetry = makeFetchRetry(fetch)

function fetchJson(url) {
	return fetchRetry(url, {
		retries: 3,
		retryDelay: 1000,
	})
		.catch((err) => {
			const error = new Error(`Could not fetch ${url}: ${err}`)
			error.cause = err
			throw error
		})
		.then(checkStatus)
		.then((res) => res.json())
}

const endpoints = {
	indicators: { path: "/indicators" },
	indicatorForArea: ({ areaSlug, indicatorSlug }) => ({
		path: `/indicators/area/${areaSlug}/${indicatorSlug}`,
		queryParams: { remove_na: true },
	}),
	indicatorsByTerritories: ({ territoryCodes }) => ({
		path: `/indicators/territories`,
		queryParams: { code: territoryCodes },
	}),
	populationByCountry: ({ territoryCode }) => ({
		path: `/population_by_country/${territoryCode}`,
	}),
	territories: { path: "/territories" },
	topologyByArea: ({ areaSlug }) => ({
		path: `/topologies/${areaSlug}`,
	}),
}

function buildApiUrl(options) {
	return buildUrl(config.apiBaseUrl, { disableCSV: true, ...options })
}

function fetchApiJson(endpoint) {
	const url = buildApiUrl(endpoint)
	return fetchJson(url).then((data) => data.result)
}

export function fetchIndicatorForArea(indicatorSlug, areaSlug) {
	return fetchApiJson(
		endpoints.indicatorForArea({ indicatorSlug, areaSlug }),
	).then(decodeIndicator)
}

export function fetchIndicatorsByTerritories(territoryCodes) {
	return fetchApiJson(endpoints.indicatorsByTerritories({ territoryCodes }))
}

export function fetchIndicators() {
	return fetchApiJson(endpoints.indicators)
}

export function fetchPopulationByCountry(territoryCode) {
	return fetchApiJson(endpoints.populationByCountry({ territoryCode }))
}

export function fetchTerritories() {
	return fetchApiJson(endpoints.territories)
}

export function fetchTopology(areaSlug) {
	return fetchApiJson(endpoints.topologyByArea({ areaSlug }))
}
