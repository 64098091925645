<script>
	export let value = null
	export let onlyValues = null

	import Select from "svelte-select"
	import config from "~/config.js"

	const { areas } = config
	$: selectedValue = areas.find((area) => area.slug === value)
	$: items = onlyValues
		? areas.filter((area) => onlyValues.includes(area.slug))
		: areas

	function getSelectionLabel(option) {
		if (option) return "Zone: " + option.label
	}
</script>

<Select
	noOptionsMessage="Aucun résultat"
	optionIdentifier="slug"
	placeholder="Sélectionnez une zone géographique..."
	{getSelectionLabel}
	{items}
	{selectedValue}
	on:select />
