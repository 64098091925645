<script>
	export let feature
	export let indicator

	import { url } from "@sveltech/routify"
	import { formatScaleValue } from "~/indicator.js"
</script>

<!-- Country or direction régionale name -->
<div>{feature.properties.name}</div>

<!-- Indicator name : value unit -->
<div>
	{indicator.name}&nbsp;:
	{formatScaleValue(feature.properties.value, indicator)}
</div>

<!-- Year -->
{#if feature.properties.year}
	<div>Année&nbsp;: {feature.properties.year}</div>
{/if}

<a
	href={$url('charts', {
		territoryCode: [feature.properties.code],
		indicatorSlug: [indicator.slug],
	})}
	class="link sm:hidden">
	Voir les graphiques
</a>
