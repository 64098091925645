<script>
	export let observations
	export let legend

	import YearSlider from "~/components/YearSlider.svelte"
	import { debounce } from "~/utils.js"
	import PyramidSvg from "./PyramidSvg.svelte"

	let selectedYear

	function compareRanges(r1, r2) {
		const startR1 = r1.split("-")[0]
		const startR2 = r2.split("-")[0]
		return startR1 - startR2
	}

	let years = Object.keys(observations).map((y) => parseInt(y))
	const currentYear = new Date().getFullYear()
	selectedYear = Math.min(years[years.length - 1], currentYear)

	let dataAtYear

	function calcDataAtYear(year) {
		let ageRanges = {}
		legend.age.forEach((item) => {
			ageRanges[item[0]] = item[1]
		})
		let countPerRange = {}
		observations[year].forEach((item) => {
			const sex = item[0]
			const rangeCode = item[1]
			const count = item[2]
			const range = ageRanges[rangeCode]
			if (countPerRange[range] === undefined) {
				countPerRange[range] = {}
			}
			if (sex === "F") {
				countPerRange[range].female = count
			} else if (sex === "M") {
				countPerRange[range].male = count
			}
		})

		let ranges = Object.keys(countPerRange).sort(compareRanges)

		return ranges.map((range) => ({
			age: range,
			male: countPerRange[range].male,
			female: countPerRange[range].female,
		}))
	}

	let dataByYear = {}
	years.forEach((year) => {
		dataByYear[year] = calcDataAtYear(year)
	})

	function updateDataAtYear() {
		dataAtYear = dataByYear[selectedYear]
	}

	updateDataAtYear()
</script>

<div class="mb-6">
	<PyramidSvg {dataAtYear} />
</div>

<YearSlider
	bind:value={selectedYear}
	{years}
	on:update={debounce(updateDataAtYear)} />
