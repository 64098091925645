<script>
	import { url } from "@sveltech/routify"
	import config from "~/config.js"
</script>

<a href={$url('home')} class="flex items-center text-africartes-gray" on:click>
	<img
		src={$url('/logo-192.png')}
		alt={`Logo ${config.appTitle}`}
		class="w-10 flex-shrink-0" />
	<span
		class="ml-1 font-bold tracking-wide text-md sm:text-xl">{config.appTitle}</span>
</a>
