import { SCALE_CONTINUOUS, SCALE_DISCRETE } from "~/indicator.js"

export const sourcePrefix = "Source"
export const copyright = "© AFD – Agence Française de Développement"

export function formatScale(scale, label, min, max) {
	if (scale.type === SCALE_CONTINUOUS) {
		if (min === -Infinity) {
			return `< ${max}`
		} else if (max === Infinity) {
			return `⩾ ${min}`
		} else {
			// Avoid using a dash to separate min and max to avoid confusion with negative numbers.
			return `${min} à ${max}`
		}
	} else if (scale.type === SCALE_DISCRETE) {
		return label
	}
}
