<script>
	import { Router } from "@sveltech/routify"
	import { routes } from "../.routify/routes"
	import { fetchIndicators, fetchTerritories } from "~/api.js"
	import {
		indicatorCategories,
		indicatorsMetadata,
		loading,
		territoriesMetadata,
	} from "~/stores.js"
	import {
		checkCategories,
		checkIndicators,
		TerritoriesMetadata,
	} from "~/model.js"
	import { withPopulationPyramid } from "~/indicator.js"

	import "~/styles.css"

	let fetchDataError

	async function fetchAppData() {
		fetchDataError = null
		let data
		try {
			data = await loading.wrap(
				Promise.all([fetchIndicators(), fetchTerritories()]),
			)
		} catch (error) {
			fetchDataError = error
			throw error
		}

		checkCategories(data[0].categories)
		checkIndicators(data[0].indicators)

		$indicatorCategories = data[0].categories
		$indicatorsMetadata = withPopulationPyramid(data[0].indicators)
		$territoriesMetadata = new TerritoriesMetadata(data[1])
	}

	fetchAppData()

	$: appDataLoaded =
		$indicatorCategories.length > 0 &&
		$indicatorsMetadata.length > 0 &&
		Object.keys($territoriesMetadata).length > 0
</script>

{#if fetchDataError}
	<div class="alert">Impossible de charger les données initiales.</div>
{:else if appDataLoaded}
	<Router {routes} />
{:else}
	<div>Chargement des données initiales en cours...</div>
{/if}
