<script>
	export let projection = geoEckert4()

	import { getContext } from "svelte"
	import { scale } from "svelte/transition"
	import { url } from "@sveltech/routify"
	import { v4 as uuidv4 } from "uuid"
	import * as geo from "d3-geo"
	import { geoEckert4 } from "d3-geo-projection"
	import { isDesktop } from "~/stores.js"
	import portal from "~/actions/portal.js"
	import Tooltip from "~/components/Tooltip.svelte"
	import FeaturePath from "./FeaturePath.svelte"
	import Stripes from "./Stripes.svelte"
	import FeatureTooltipContent from "./FeatureTooltipContent.svelte"

	const { data, width, height } = getContext("LayerCake")

	const { featureTooltip, indicator } = getContext("Map")

	$: generatePath = geo.geoPath(projection.fitSize([$width, $height], $data))

	// Make ID unique across many charts. Using index would not be unique enough.
	const stripesId = `stripes-${uuidv4()}`

	// Tooltip
	$: popperContent = $featureTooltip && $featureTooltip.popperContent
	const popperOptions = {
		modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
	}
</script>

<g class="map-group" transition:scale|local stroke-width="1" stroke="#ffffff">
	{#each $data.features as feature}
		{#if feature.properties.crosshatched}
			<Stripes id={stripesId} stroke={feature.properties.color} />
		{/if}
		<a
			href={indicator ? $url('charts', {
						territoryCode: [feature.properties.code],
						indicatorSlug: [indicator.slug],
				  }) : null}>
			<FeaturePath {feature} {generatePath} {stripesId} />
		</a>
	{/each}
</g>

{#if $featureTooltip}
	<div use:popperContent={popperOptions} use:portal>
		<Tooltip
			closeButton={!$isDesktop}
			on:close={() => {
				$featureTooltip = false
			}}>
			<FeatureTooltipContent {indicator} feature={$featureTooltip.feature} />
		</Tooltip>
	</div>
{/if}

<style>
	.map-group {
		transform-origin: center;
	}
</style>
